<template>
<div class="top-bar">
    <nav class="nav">
      <ul class="nav-block">
        <li class="nav-list">
          <a
              href="https://cemglobal.com/"
              class="nav-link">
              Our Company
          </a>
        </li>
        <li
            class="nav-list"
            v-for="(navItem, idx) in topBarData"
            :key="idx"
        >
          <a
              :href="navItem.link"
              class="nav-link"
          >
            <img
                :src="navItem.logo"
                alt="logo">
          </a>
        </li>
      </ul>
    </nav>
    <nav class="nav-mobile">
      <div class="nav-mobile-top">
        <p @click="ShowNavMobile">
          Our Company
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
        </p>
        <a href="https://cemglobal.com/" class="nav-mobile-top-link">
          <img src="https://continental.one/img/white-logo/sem-logo.jpg" style='width: 176px;' alt="logo">
        </a>
      </div>
      <ul
          class="nav-mobile-block"
          v-if="topBarShow"
      >
        <li
            class="nav-mobile-block-list"
            v-for="(navItem, idx) in topBarData"
            :key="idx"
        >
          <a :href="navItem.link" class="nav-mobile-block-link">
            {{navItem.name}}
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11 13H1C0.4 13 0 12.6 0 12V2C0 1.4 0.4 1 1 1H6V2H1V12H11V7H12V12C12 12.6 11.6 13 11 13ZM8 0V1H11.3L7 5.3L7.7 6L12 1.7V5H13V0H8Z"
                    fill="white"></path>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data (){
    return{
      topBarData: [
        {
          logo: `${require('@/assets/images/topBar/logo-prof.svg')}`,
          link: 'https://proseries.one/',
          name: 'Professional Series'
        },
        {
          logo: `${require('@/assets/images/topBar/logo-collezioni.png')}`,
          link: 'https://collezioni.com',
          name: 'Collezioni'
        },
        {
          logo: `${require('@/assets/images/topBar/brands-logo-1.svg')}`,
          link: 'https://continental.one/',
          name: 'Continental'
        },
        {
          logo: `${require('@/assets/images/topBar/logo-main.svg')}`,
          link: 'https://gymmaster.fitness/',
          name: 'Gym Master'
        },
        {
          logo: `${require('@/assets/images/ce-logo.png')}`,
          link: 'https://ceconsumer.com/#/',
          name: 'Ceconsumer'
        },
        {
          logo: `${require('@/assets/img/brands/logo-autdoors.png')}`,
          link: 'http://americanoutdoors.life/',
          name: 'American outdoors'
        },
        // {
        //   logo: `${require('@/assets/img/brands/logo.png')}`,
        //   link: 'http://Savage.bike',
        //   name: 'Savage'
        // }
      ],
      topBarShow: false
    }
  },
  methods: {
    ShowNavMobile (){
      this.topBarShow = !this.topBarShow
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.top-bar{
  width: 100%;
  background-color: #000;
    .nav{
      padding-left: 5rem;
      padding-right: 5rem;
      .nav-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-list{
          &:nth-child(2) {
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: block;
              img {
                width: 5rem;
              }
            }
          }
          &:nth-child(3) {
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: block;
              img {
                width: 8rem;
              }
            }
          }
          &:nth-child(4) {
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: block;
              img {
                width: 7rem;
              }
            }
          }
          &:nth-child(5) {
            background-color: $blackBg;
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: flex;
              height: 34px;
              align-items: center;
              justify-content: center;
              img {
                width: 5rem;
              }
            }
          }
          &:nth-child(6) {
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: block;
              width: 6rem;
              box-sizing: border-box;
              img {
                width: 6rem;
                max-width: 100%;
              }
            }
          }
          &:nth-child(7) {
            .nav-link {
              padding: 1.3rem 0.75rem;
              display: block;
              img {
                width: 6rem;
              }
            }
          }
          &:nth-child(1){
            max-width: 118px;
            .nav-link{
              border-right: 2px solid rgb(255 255 255);
              color: rgb(255 255 255);
              padding-right: 1rem;
            }
          }
        }
      }
    }
    .nav-mobile{
      display: none;
      .nav-mobile-block{
        .nav-mobile-block-list{
          padding: 0.5rem 0.75rem;
          margin: 5px 10px;
          width: fit-content;
          .nav-mobile-block-link{
            color: rgb(255 255 255);
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 600;
            & > svg{
              margin-left: 8px;
            }
          }
          &:last-child{
            background-color: $white;
            & > a{
              color: #000;
              & > svg{
                path{
                  fill: #000;
                }
              }
            }
          }
        }
      }
      .nav-mobile-top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p{
          color: rgb(255 255 255);
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 600;
          padding: 0.5rem 0.75rem;
          margin: 5px;
          display: flex;
          & > svg{
            width: 1.25rem;
            margin-left: 8px;
          }
        }
        & > .nav-mobile-top-link{
            padding: 15px 16px 15px 0;
          & > img{
            width: 100%; 
            object-fit: cover;
          }
        }
      }
    }
}
@media (max-width: 1200px) {
  .top-bar{
    .nav{
      padding: 0;
      .nav-block{
        flex-wrap: wrap;
        .nav-list{
          max-width: 100% !important;
          width: 100%;
            padding: 10px 0;
          & > a{
            max-width: 115px;
            display: block;
            margin: 0 auto;
            & > img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .top-bar{
    .nav{
      display: none;
    }
    .nav-mobile{
      display: block;
    }
  }
}
</style>
